import { Tools } from 'components/Sketch';
import {
  DrawIcon,
  LineIcon,
  RectangleIcon,
  CircleIcon
} from 'components/Icons';

export const UDAF_EDITOR_TOOLS = [
  {
    icon: DrawIcon,
    name: 'Draw',
    type: Tools.Pencil
  },
  {
    icon: LineIcon,
    name: 'Line',
    type: Tools.Line
  },
  {
    icon: RectangleIcon,
    name: 'Rect',
    type: Tools.Rectangle
  },
  {
    icon: CircleIcon,
    name: 'Circle',
    type: Tools.Circle
  }
];
