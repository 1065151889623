const UDAF_TOKEN_KEY = 'udaf-token-key';

export const getToken = () => {
  return window.localStorage.getItem(UDAF_TOKEN_KEY);
};

export const setToken = (authToken) => {
  window.localStorage.setItem(UDAF_TOKEN_KEY, authToken);
};

export const removeToken = () => {
  window.localStorage.removeItem(UDAF_TOKEN_KEY);
};

export default {
  getToken,
  setToken,
  removeToken
};
