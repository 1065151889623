import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input';
import Button from 'components/Button';
import Context from 'service/context';
import { authenticate } from 'service/api';
import { validateFields } from 'common/helpers';
import { INITIAL_VALUE, DESCRIPTOR } from './constants';

const Login = ({ onSuccess, onError }) => {
  const options = useContext(Context);
  const [formData, setFormData] = useState(INITIAL_VALUE);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (options.API_BASE_URL) {
      const result = await validateFields(DESCRIPTOR, formData);

      setFieldErrors(result);

      if (Object.keys(result).length === 0) {
        authenticate(options.API_BASE_URL, formData)
          .then(onSuccess)
          .catch((error) => onError(error.response));
      }
    } else {
      console.error(
        'Please wrap your app with the UdafProvider and pass the correct API_BASE_URL.'
      );
    }
  };

  const handleTextChange = (ev, field) => {
    setFormData({ ...formData, [field]: ev.target.value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        data-id="email"
        type="email"
        placeholder="Email *"
        value={formData.email}
        onChange={(ev) => handleTextChange(ev, 'email')}
        error={fieldErrors.email}
      />
      <Input
        data-id="password"
        type="password"
        placeholder="Password *"
        value={formData.password}
        onChange={(ev) => handleTextChange(ev, 'password')}
        error={fieldErrors.password}
        togglePassword
      />
      <center>
        <Button type="submit" data-id="login-button">
          log in
        </Button>
      </center>
    </form>
  );
};

Login.propTypes = {
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired
};

export default Login;
