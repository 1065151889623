const useCamera = () => {
  const deviceHasCamera = () => {
    return (
      'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices
    );
  };

  const requestCameraAccess = (width = 400, height = 400) => {
    return navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment', width, height },
      audio: false
    });
  };

  return {
    deviceHasCamera,
    requestCameraAccess
  };
};

export default useCamera;
