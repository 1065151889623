import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback
} from 'react';
import PropTypes from 'prop-types';

import { SearchIcon, Spinner } from 'components/Icons';
import useDebounce from 'utils/useDebounce';
import { getBrandImages } from 'service/api';
import Context from 'service/context';
import Result from './components/Result';

const ImageSearch = ({ onSelect }) => {
  const options = useContext(Context);
  const [focused, setFocused] = useState(false);
  const [attempted, setAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState();
  const queryRef = useRef();

  const debouncedQuery = useDebounce(query, 500);

  const handleQueryChange = (ev) => {
    setQuery(ev.target.value);
    setPage(0);
  };

  const handleIncrementPage = () => {
    if (total !== result.length) {
      setPage(page + 1);
    }
  };

  const searchBrandImages = useCallback(() => {
    const didQueryChange = queryRef.current !== debouncedQuery;
    const didLimit = !didQueryChange && total === result.length;

    if (debouncedQuery && !didLimit) {
      if (options.API_BASE_URL) {
        const didQueryChange = queryRef.current !== debouncedQuery;

        setLoading(true);

        getBrandImages(options.API_BASE_URL, debouncedQuery, page, 40)
          .then((response) => {
            const { content, totalElements } = response.data;

            queryRef.current = debouncedQuery;

            setTotal(totalElements);

            if (didQueryChange) {
              setResult(content);
            } else {
              setResult([...result, ...content]);
            }
          })
          .catch(({ response }) => {
            window.alert(
              (response && response?.message) ||
                'Oops! Something went wrong. Please try again!'
            );
          })
          .finally(() => {
            setLoading(false);
            setAttempted(true);
          });
      } else {
        console.error(
          'Please wrap your app with the UdafProvider and pass the correct API_BASE_URL.'
        );
      }
    } else if (!debouncedQuery) {
      setLoading(false);
      setAttempted(false);
      setResult([]);
      setPage(0);
      setTotal(null);
    }
  }, [debouncedQuery, page]);

  useEffect(() => {
    searchBrandImages();
  }, [debouncedQuery, searchBrandImages, page]);

  return (
    <div className="udaf-image-search">
      <div className="udaf-search">
        <input
          type="text"
          placeholder="Enter letters or symbols here"
          className="udaf-search__input"
          onChange={handleQueryChange}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {loading ? (
          <div className="udaf-search__icon">
            <Spinner />
          </div>
        ) : (
          <div className="udaf-search__icon">
            <SearchIcon />
          </div>
        )}
        <Result
          result={result}
          visible={focused && attempted && !!query}
          nextPage={handleIncrementPage}
          onSelect={onSelect}
        />
      </div>
    </div>
  );
};

ImageSearch.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default ImageSearch;
