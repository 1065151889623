import React, { useRef, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useCamera from './useCamera';
import {
  CloseIcon,
  CameraIcon,
  // FlipCameraIcon,
  CheckIcon,
  ReplayIcon
} from 'components/Icons';

const Camera = React.forwardRef(({ onPhotoTaken, width, height }, ref) => {
  const videoRef = useRef();
  const [image, setImage] = useState();
  const [stream, setStream] = useState();
  const [streaming, setStreaming] = useState(false);

  const { deviceHasCamera, requestCameraAccess } = useCamera();

  const startCamera = () => {
    if (deviceHasCamera()) {
      requestCameraAccess()
        .then((videoStream) => {
          setStreaming(true);
          setStream(videoStream);
          videoRef.current.srcObject = videoStream;
        })
        .catch(() => {
          window.alert('Permission denied.');
        });
    } else {
      window.alert('No available camera.');
    }
  };

  const takePhoto = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.setAttribute('width', '400px');
    canvas.setAttribute('height', '400px');
    context.drawImage(videoRef.current, 0, 0, 400, 400);

    const data = canvas.toDataURL('image/png');

    setImage(data);
  };

  const replacePhoto = () => {
    setImage(null);
  };

  const handleStop = () => {
    stream.getTracks().forEach((track) => {
      track.stop();
    });

    setStream(null);
    setImage(null);
    setStreaming(false);
  };

  const confirmPhoto = () => {
    onPhotoTaken(image);
    handleStop();
  };

  useEffect(() => {
    if (ref) {
      ref.current = {
        startCamera,
        getImage: () => image,
        getStream: () => stream,
        getIsStreaming: () => streaming,
        getVideo: () => videoRef.current
      };
    }
  }, []);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  useEffect(() => {
    if (streaming) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [streaming]);

  return (
    <div
      className={clsx({
        'udaf-camera': true,
        'udaf-camera--open': streaming
      })}
    >
      <div className="udaf-camera__content">
        {image ? (
          <Fragment>
            <div className="udaf-camera__controls">
              <button
                type="button"
                onClick={handleStop}
                className="udaf-camera__control udaf-camera__close"
              >
                <CloseIcon />
              </button>
              <button
                type="button"
                className="udaf-camera__control"
                onClick={confirmPhoto}
              >
                <CheckIcon />
              </button>
              <button
                type="button"
                onClick={replacePhoto}
                className="udaf-camera__control"
              >
                <ReplayIcon />
              </button>
            </div>
            <img
              src={image}
              alt=""
              className="udaf-camera__preview"
              width={width}
              height={height}
            />
          </Fragment>
        ) : (
          <div className="udaf-camera__controls">
            <button
              type="button"
              onClick={handleStop}
              className="udaf-camera__control udaf-camera__close"
            >
              <CloseIcon />
            </button>
            <button
              type="button"
              onClick={takePhoto}
              className="udaf-camera__control"
            >
              <CameraIcon />
            </button>
            {/* <button type="button" className="udaf-camera__control">
              <FlipCameraIcon />
            </button> */}
          </div>
        )}
        {stream && (
          <video
            ref={videoRef}
            autoPlay
            height={height}
            width={width}
            style={{ display: image ? 'none' : 'block' }}
            className="udaf-camera__video"
          />
        )}
      </div>
    </div>
  );
});

Camera.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  onPhotoTaken: PropTypes.func
};

Camera.defaultProps = {
  width: 400,
  height: 400,
  onPhotoTaken: () => {}
};

export default Camera;
