import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import Context from 'service/context';
import utahidLogo from 'images/utahid-logo-full-black.svg';
import googleLogo from 'images/google-logo.png';
import facebookLogo from 'images/fb-logo.png';
import { OAUTH_PROVIDERS } from './constants';

const Oauth = ({ beforeAuth, defaultProviders }) => {
  const { GOOGLE_AUTH_URL, FACEBOOK_AUTH_URL, UTAHID_AUTH_URL } = useContext(
    Context
  );

  const handleGoogleAuth = () => {
    if (GOOGLE_AUTH_URL) {
      if (beforeAuth) {
        beforeAuth();
      }

      window.location.href = GOOGLE_AUTH_URL;
    } else {
      console.error('GOOGLE_AUTH_URL is not provided.');
    }
  };

  const handleFacebookAuth = () => {
    if (FACEBOOK_AUTH_URL) {
      if (beforeAuth) {
        beforeAuth();
      }

      window.location.href = FACEBOOK_AUTH_URL;
    } else {
      console.error('FACEBOOK_AUTH_URL is not provided.');
    }
  };

  const handleUtahIdAuth = () => {
    if (UTAHID_AUTH_URL) {
      if (beforeAuth) {
        beforeAuth();
      }

      window.location.href = UTAHID_AUTH_URL;
    } else {
      console.error('UTAHID_AUTH_URL is not provided.');
    }
  };

  return (
    <div className="udaf-oauth">
      {defaultProviders.includes(OAUTH_PROVIDERS.UTAHID) && UTAHID_AUTH_URL && (
        <Button secondary type="button" onClick={handleUtahIdAuth}>
          Login with
          <img
            className="udaf-oauth__utahidlogo"
            src={utahidLogo}
            alt="UtahID"
          />
        </Button>
      )}
      {defaultProviders.includes(OAUTH_PROVIDERS.GOOGLE) && GOOGLE_AUTH_URL && (
        <Button secondary type="button" onClick={handleGoogleAuth}>
          <img
            className="udaf-oauth__logo"
            src={googleLogo}
            alt="Login with Google"
          />
          Login with Google
        </Button>
      )}
      {defaultProviders.includes(OAUTH_PROVIDERS.FACEBOOK) &&
        FACEBOOK_AUTH_URL && (
          <Button secondary type="button" onClick={handleFacebookAuth}>
            <img
              className="udaf-oauth__logo"
              src={facebookLogo}
              alt="Login with Facebook"
            />
            Login with Facebook
          </Button>
        )}
      {!GOOGLE_AUTH_URL && !FACEBOOK_AUTH_URL && (
        <p>
          <i>No oauth providers.</i>
        </p>
      )}
    </div>
  );
};

Oauth.propTypes = {
  beforeAuth: PropTypes.func,
  defaultProviders: PropTypes.arrayOf(PropTypes.string)
};

Oauth.defaultProps = {
  defaultProviders: Object.keys(OAUTH_PROVIDERS)
};

export default Oauth;
