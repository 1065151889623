import React, { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SketchField, Tools } from 'components/Sketch';
import Button from 'components/Button';
import {
  SelectIcon,
  RemoveIcon,
  EraserIcon,
  RedoIcon,
  UndoIcon
} from 'components/Icons';
import TextHelper from './components/TextHelper';
import UploadHelper from './components/UploadHelper';
import DialogHelper from './components/DialogHelper';
import { UDAF_EDITOR_TOOLS } from './constants';

const BrandEditor = ({
  imageUrl,
  sketchRef: mainRef,
  hasUpload,
  hasText,
  hasCameraOption,
  onNext
}) => {
  const image = useRef();
  const [tool, setTool] = useState(Tools.Pencil);
  const [brandObj, setBrandObject] = useState(null);
  const [eraser, setEraser] = useState(false);
  const sketchRef = mainRef || useRef();

  const handleToolChange = (type) => {
    setTool(type);
    setEraser(false);
  };

  const handleEraser = () => {
    setEraser(true);
    setTool(Tools.Pencil);
  };

  const handleAddText = (text) => {
    sketchRef.current.addText(text, { fontFamily: 'Arial', zIndex: '1000' });
  };

  const handleUndo = () => {
    if (sketchRef.current.canUndo()) {
      sketchRef.current.undo();
    }
  };

  const handleRedo = () => {
    if (sketchRef.current.canRedo()) {
      sketchRef.current.redo();
    }
  };

  const handleFileChange = (file) => {
    const hasObjects = sketchRef?.current?.toJSON().objects.length > 0 || false;

    if (hasObjects) {
      setBrandObject(file);
    } else {
      replaceSketchByFile(file);
    }
  };

  const clearBrandObj = () => {
    setBrandObject(null);
  };

  const replaceSketchByFile = (file) => {
    const reader = new window.FileReader();

    reader.addEventListener('load', () => {
      sketchRef.current.clear();

      window.fabric.Image.fromURL(reader.result, (oImg) => {
        const fc = sketchRef.current._fc;

        if (oImg.height > oImg.width) {
          oImg.scaleToHeight(fc.getHeight());
        } else {
          oImg.scaleToWidth(fc.getWidth());
        }

        oImg.set({ left: 0, top: 0 });

        fc.add(oImg);

        handleToolChange(Tools.Select);
        fc.setActiveObject(oImg);
      });
    });

    reader.readAsDataURL(file);
  };

  const replaceSketchByImage = useCallback(
    (url) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new window.Image();

      canvas.width = 200;
      canvas.height = 200;

      img.crossOrigin = 'anonymous';
      img.src = url;
      img.onload = () => {
        ctx.drawImage(img, 0, 0, 200, 200);

        const test = canvas.toDataURL();

        sketchRef.current.clear();

        window.fabric.Image.fromURL(test, (oImg) => {
          const fc = sketchRef.current._fc;

          oImg.scaleToWidth(fc.getWidth());
          oImg.scaleToHeight(fc.getHeight());
          oImg.set({ left: 0, top: 0 });

          fc.add(oImg);
        });
      };
    },
    [sketchRef]
  );

  useEffect(() => {
    if (imageUrl && imageUrl !== image.current) {
      image.current = imageUrl;

      const hasObjects =
        sketchRef?.current?.toJSON().objects.length > 0 || false;

      if (hasObjects) {
        setBrandObject(imageUrl);
      } else {
        replaceSketchByImage(imageUrl);
      }
    }
  }, [imageUrl, replaceSketchByImage, sketchRef]);

  return (
    <div className="udaf-editor">
      <div className="udaf-editor__main">
        <div className="udaf-editor__selectors">
          <Button
            secondary={tool !== Tools.Select}
            sm
            onClick={() => handleToolChange(Tools.Select)}
            className="udaf-editor__action"
          >
            <SelectIcon />
            Select
          </Button>
          <Button
            secondary
            sm
            onClick={sketchRef?.current?.removeSelected}
            className="udaf-editor__action"
          >
            <RemoveIcon />
            Remove
          </Button>
          <Button
            secondary={!eraser}
            sm
            onClick={handleEraser}
            className="udaf-editor__action"
          >
            <EraserIcon />
            Eraser
          </Button>
        </div>
        <div className="udaf-editor__row">
          <div className="udaf-editor__tools">
            {hasText && <TextHelper handleAddText={handleAddText} />}
            {hasUpload && (
              <UploadHelper
                handleFileChange={handleFileChange}
                hasCameraOption={hasCameraOption}
              />
            )}
            {UDAF_EDITOR_TOOLS.map((t) => (
              <Button
                key={t.name}
                secondary={tool !== t.type || eraser}
                sm
                onClick={() => handleToolChange(t.type)}
                className="udaf-editor__action"
              >
                <t.icon />
                {t.name}
              </Button>
            ))}
          </div>
          <div className="udaf-editor__sketch">
            <SketchField
              name="udafSketch"
              tool={tool}
              ref={sketchRef}
              width={200}
              height={200}
              lineColor={eraser ? '#FFFFFF' : '#000000'}
              lineWidth={eraser ? 20 : 8}
              backgroundColor="#FFFFFF"
            />
          </div>
        </div>
      </div>
      <div className="udaf-editor__nav">
        <Button
          secondary
          sm
          onClick={handleUndo}
          className="udaf-editor__action"
          block
        >
          <UndoIcon />
          Undo
        </Button>
        <Button
          secondary
          sm
          onClick={handleRedo}
          className="udaf-editor__action"
          block
        >
          <RedoIcon />
          Redo
        </Button>
        {!!onNext && (
          <Button sm block onClick={onNext}>
            Next
          </Button>
        )}
      </div>
      <DialogHelper
        brandObj={brandObj}
        replaceSketchByFile={replaceSketchByFile}
        replaceSketchByImage={replaceSketchByImage}
        onClose={clearBrandObj}
      />
    </div>
  );
};

BrandEditor.propTypes = {
  imageUrl: PropTypes.string,
  sketchRef: PropTypes.object,
  hasUpload: PropTypes.bool,
  hasText: PropTypes.bool,
  hasCameraOption: PropTypes.bool,
  onNext: PropTypes.func
};

BrandEditor.defaultProps = {
  imageUrl: '',
  hasUpload: false,
  hasText: false,
  hasCameraOption: false
};

export default BrandEditor;
