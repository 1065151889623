import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/Button';

const TextHelper = ({ handleAddText }) => {
  const [text, setText] = useState('');

  const handleSubmit = () => {
    handleAddText(text);
    setText('');
  };

  return (
    <React.Fragment>
      <input
        type="text"
        placeholder="Text"
        className="udaf-editor__text"
        value={text}
        onChange={(ev) => setText(ev.target.value)}
      />
      <Button
        secondary
        sm
        className="udaf-editor__action"
        onClick={handleSubmit}
      >
        Add text
      </Button>
    </React.Fragment>
  );
};

TextHelper.propTypes = {
  handleAddText: PropTypes.func.isRequired
};

export default TextHelper;
