import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';

import Dialog from 'components/Dialog';
import Button from 'components/Button';

const DialogHelper = ({
  brandObj,
  replaceSketchByFile,
  replaceSketchByImage,
  onClose
}) => {
  const [image, setImage] = useState();
  const cropperRef = useRef();
  const [crop, setCrop] = useState({
    aspect: 1, // 16 / 16
    unit: 'px',
    width: 200,
    height: 200
  });
  const isFileImage = !!brandObj && typeof brandObj === 'object';

  const confirm = async () => {
    if (isFileImage) {
      const blob = await getCroppedImg(
        cropperRef.current,
        crop,
        'brand-upload.jpeg'
      );

      replaceSketchByFile(blob);
    } else {
      replaceSketchByImage(brandObj);
    }

    onClose();
  };

  const onImageLoaded = (img) => {
    cropperRef.current = img;
  };

  const getCroppedImg = (image, cropData, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = cropData.width;
    canvas.height = cropData.height;

    ctx.drawImage(
      image,
      cropData.x * scaleX,
      cropData.y * scaleY,
      cropData.width * scaleX,
      cropData.height * scaleY,
      0,
      0,
      cropData.width,
      cropData.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          resolve(blob);
        },
        'image/jpeg',
        1
      );
    });
  };

  useEffect(() => {
    if (!!brandObj && isFileImage) {
      const reader = new window.FileReader();

      reader.addEventListener('load', () => {
        setImage(reader.result);
      });

      reader.readAsDataURL(brandObj);
    }
  }, [brandObj, isFileImage]);

  return (
    <Dialog open={!!brandObj} onClose={onClose}>
      <div>
        <p>
          This will overwrite your current changes. Are you sure you want to
          continue?
        </p>
        {isFileImage && image && (
          <Fragment>
            <h3>Crop Image:</h3>
            <div>
              <ReactCrop
                src={image}
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onImageLoaded={onImageLoaded}
                style={{
                  minWidth: '200px',
                  minHeight: '200px',
                  maxHeight: '400px',
                  maxWidth: '400px'
                }}
              />
            </div>
          </Fragment>
        )}
        <div style={{ textAlign: 'right' }}>
          <Button sm secondary style={{ marginRight: '5px' }} onClick={onClose}>
            Cancel
          </Button>
          <Button sm onClick={confirm}>
            confirm
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

DialogHelper.propTypes = {
  brandObj: PropTypes.object,
  replaceSketchByFile: PropTypes.func.isRequired,
  replaceSketchByImage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default DialogHelper;
