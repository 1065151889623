import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ImageIcon } from 'components/Icons';
import Button from 'components/Button';
import Camera from 'components/Camera';

const UploadHelper = ({ handleFileChange, hasCameraOption }) => {
  const uploadRef = useRef();
  const cameraRef = useRef();
  const [activeHover, setActiveHover] = useState(false);

  const handleDragOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActiveHover(true);
  };

  const handleDragLeave = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setActiveHover(false);
  };

  const handleDrop = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const dt = ev.dataTransfer;

    handleFileChange(dt.files[0]);
    setActiveHover(false);
  };

  const handleChange = (ev) => {
    const files = ev.target.files;

    handleFileChange(files[0]);
  };

  const handleAttachment = () => {
    uploadRef.current.click();
  };

  const handlePhotoTaken = (image) => {
    handleFileChange(image);
  };

  const handlePhoto = () => {
    if (cameraRef.current) {
      cameraRef.current.startCamera();
    }
  };

  return (
    <div className="udaf-editor-upload">
      <label
        className={clsx({
          'udaf-editor-upload__area': true,
          'udaf-editor-upload__area--active': activeHover
        })}
        ref={uploadRef}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input type="file" onChange={handleChange} />
        Drag'n'drop images or click to select files
      </label>
      {hasCameraOption ? (
        <React.Fragment>
          <Button
            secondary
            sm
            className="udaf-editor__action"
            onClick={handlePhoto}
          >
            <ImageIcon />
            Photo
          </Button>
          <Camera ref={cameraRef} onPhotoTaken={handlePhotoTaken} />
        </React.Fragment>
      ) : (
        <Button
          secondary
          sm
          className="udaf-editor__action"
          onClick={handleAttachment}
        >
          <ImageIcon />
          upload
        </Button>
      )}
    </div>
  );
};

UploadHelper.propTypes = {
  handleFileChange: PropTypes.func.isRequired,
  hasCameraOption: PropTypes.bool.isRequired
};

export default UploadHelper;
