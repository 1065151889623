import React, { useEffect } from 'react';
import queryString from 'query-string';
import { setToken } from 'utils/authToken';
import { setmonthToken } from 'utils/months';

const AuthInterceptor = ({ location, history }) => {
  const query = queryString.parse(location.search);

  if (query.token) {
    setToken(query.token);
  }

  if (query.monthsData) {
    setmonthToken(query.monthsData);
  }

  useEffect(() => {
    if (history) {
      history.push('/');
    } else {
      window.location.href = '/';
    }
  }, [history]);

  return <div>Authenticating...</div>;
};

export default AuthInterceptor;
