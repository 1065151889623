import { useContext } from 'react';
import Context from 'service/context';
import { removeToken } from './authToken';

const useAuthentication = (type) => {
  const options = useContext(Context);
  const origin = window.location.origin;
  const redirectUri = window.encodeURIComponent(`${origin}/authenticate`);

  return {
    login: () => {
      window.location.href = `${options.AUTH_URL}?redirect_uri=${redirectUri}`;
    },
    signup: () => {
      window.location.href = `${options.AUTH_URL}/signup?redirect_uri=${redirectUri}`;
    },
    forgotPassword: () => {
      window.location.href = `${options.AUTH_URL}/forgot-password?redirect_uri=${redirectUri}`;
    },
    logout: (callback) => {
      removeToken();

      if (typeof callback === 'function') {
        callback();
      }
    }
  };
};

export default useAuthentication;
