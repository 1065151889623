import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { debounce } from 'utils/useDebounce';

const Result = ({ result, visible, nextPage, onSelect }) => {
  const hasResult = result.length > 0;
  const resultsRef = useRef();
  const contentRef = useRef();

  const handleScroll = debounce(() => {
    if (contentRef.current && resultsRef.current) {
      const diff =
        contentRef.current.offsetHeight -
        (resultsRef.current.offsetHeight + resultsRef.current.scrollTop);

      if (diff < 5) {
        nextPage();
      }
    }
  }, 500);

  return (
    <div
      className={clsx({
        'udaf-search-result': true,
        'udaf-search-result--active': visible
      })}
      ref={resultsRef}
      onScroll={handleScroll}
    >
      {hasResult ? (
        <div className="udaf-search-result__content" ref={contentRef}>
          {result.map((image, index) => (
            <button
              key={`${image.id}${index}`}
              className="udaf-search-result__btn"
              onClick={() => onSelect(image)}
            >
              <img
                src={image.imageUrl}
                alt={image.letters}
                className="udaf-search-result__img"
              />
            </button>
          ))}
        </div>
      ) : (
        <p className="udaf-search-result__empty">- no brands found -</p>
      )}
    </div>
  );
};

Result.propTypes = {
  result: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default Result;
