import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Input from 'components/Input';
import Button from 'components/Button';
import Context from 'service/context';
import { forgotPassword } from 'service/api';
import { validateFields } from 'common/helpers';
import { INITIAL_VALUE, DESCRIPTOR } from './constants';

const ForgotPassword = ({ onSuccess, onError }) => {
  const options = useContext(Context);
  const [formData, setFormData] = useState(INITIAL_VALUE);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (options.API_BASE_URL) {
      const result = await validateFields(DESCRIPTOR, formData);

      setFieldErrors(result);

      if (Object.keys(result).length === 0) {
        forgotPassword(options.API_BASE_URL, formData)
          .then(onSuccess)
          .catch((error) => onError(error.response));
      }
    } else {
      console.error(
        'Please wrap your app with the UdafProvider and pass the correct API_BASE_URL.'
      );
    }
  };

  const handleTextChange = (ev) => {
    setFormData({ ...formData, email: ev.target.value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Input
        type="email"
        placeholder="Email *"
        value={formData.email}
        onChange={handleTextChange}
        error={fieldErrors.email}
      />
      <center>
        <Button type="submit">submit</Button>
      </center>
    </form>
  );
};

ForgotPassword.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

export default ForgotPassword;
