import React from 'react';

export default () => {
  return (
    <svg
      className="udaf-icon"
      focusable="false"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path d="M19 13H5v-2h14v2z" />
    </svg>
  );
};
