import React from 'react';

export default () => {
  return (
    <svg
      className="udaf-icon udaf-spinner"
      focusable="false"
      viewBox="22 22 44 44"
      aria-hidden="true"
    >
      <circle
        cx="44"
        cy="44"
        r="20.2"
        fill="none"
        strokeWidth="3.6"
        className="udaf-spinner__circle"
      />
    </svg>
  );
};
