import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Dialog = ({ open, onClose, ...args }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [open]);

  return (
    <div
      className={clsx({
        'udaf-dialog': true,
        'udaf-dialog--open': open
      })}
      onClick={onClose}
    >
      <div
        className="udaf-dialog__content"
        onClick={(ev) => ev.stopPropagation()}
      >
        <button className="udaf-dialog__close" onClick={onClose}>
          &times;
        </button>
        {args.children}
      </div>
    </div>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

Dialog.defaultProps = {
  open: false,
  onClose: () => {}
};

export default Dialog;
