import React, { useState } from 'react';
import clsx from 'clsx';

import { InvisibleIcon, VisibleIcon } from 'components/Icons';

const Input = (props) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const showTogglePassword = props.togglePassword && props.type === 'password';
  const inputProps = { ...props };
  delete inputProps.error;
  delete inputProps.togglePassword;

  const handleShowPassword = () => {
    setPasswordVisible(true);
  };

  const handleHidePassword = () => {
    setPasswordVisible(false);
  };

  const renderIcon = () => {
    if (showTogglePassword) {
      if (passwordVisible) {
        return (
          <button
            onClick={handleHidePassword}
            className="udaf-form-control__toggle-password"
            type="button"
          >
            <VisibleIcon />
          </button>
        );
      }

      return (
        <button
          onClick={handleShowPassword}
          className="udaf-form-control__toggle-password"
          type="button"
        >
          <InvisibleIcon />
        </button>
      );
    }

    return null;
  };

  return (
    <div className="udaf-form-control">
      <input
        className={clsx({
          'udaf-form-control__input': true,
          'udaf-form-control__input--error': props.error,
          'udaf-form-control__input--password': showTogglePassword
        })}
        {...inputProps}
        type={passwordVisible ? 'text' : props.type}
      />
      {!!props.placeholder && (
        <label className="udaf-form-control__label">{props.placeholder}</label>
      )}
      <div className="udaf-form-control__set" />
      {renderIcon()}
    </div>
  );
};

export default Input;
