import axios from 'axios';

export function authenticate(apiBase, credentials) {
  return axios.post(`${apiBase}/auth/login`, credentials);
}

export function signUp(apiBase, formData) {
  return axios.post(`${apiBase}/auth/signup`, formData);
}

export function forgotPassword(apiBase, formData) {
  const location = window.location;
  const currentUrl = `${location.pathname}${location.search}`;

  return axios.post(`${apiBase}/auth/forgot-password`, {
    ...formData,
    previousUrl: currentUrl
  });
}

export function getBrandImages(apiBase, query, page, size) {
  return axios.get(`${apiBase}/images`, {
    params: {
      query,
      page,
      size
    }
  });
}
