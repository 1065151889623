const UDAF_MONTHS_DATA = 'udaf-months-data';

export const getmonthToken = () => {
  return window.localStorage.getItem(UDAF_MONTHS_DATA);
};

export const setmonthToken = (monthsData) => {
  window.localStorage.setItem(UDAF_MONTHS_DATA, monthsData);
};

export const removemonthToken = () => {
  window.localStorage.removeItem(UDAF_MONTHS_DATA);
};

export default {
  getmonthToken,
  setmonthToken,
  removemonthToken
};
