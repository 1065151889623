import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = ({ ...props }) => {
  const btnProps = { ...props };

  delete btnProps.secondary;
  delete btnProps.sm;
  delete btnProps.className;
  delete btnProps.block;

  return (
    <button
      {...btnProps}
      className={clsx(
        {
          'udaf-btn': true,
          'udaf-btn--secondary': props.secondary,
          'udaf-btn--sm': props.sm,
          'udaf-btn--block': props.block
        },
        props.className
      )}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  block: PropTypes.bool
};

Button.defaultProps = {
  secondary: false,
  sm: false,
  block: false
};

export default Button;
